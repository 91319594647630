import React, { Component } from "react"; 

class Contact extends Component {
  render() {
    return (
      <div className="width-container title">
        <h2>Contact</h2>
      </div>
    );
  }
}
 
export default Contact;