import React, { Component } from "react";

class Home extends Component {
    render() {
      return (
        <div className="width-container title">
          <h2>Greetings 😄</h2>
      </div>
      );
    }
}
   

export default Home;