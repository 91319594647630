import React, { Component } from "react"; 

class About extends Component {
  render() {
    return (
      <div className="width-container title">
        <h2>Pablo!</h2>
      </div>
    );
  }
}
 
export default About;